import React, { Component } from "react";
import "./App.css";
import logo from "./LogoDarkIcon.svg";
import PlaidLink from "react-plaid-link";
import "react-table/react-table.css";
import axios from "axios";
import {
  Button,
  TextInputField,
  Pane,
  toaster,
  Table,
  Card,
  Icon,
  Spinner,
  Heading,
} from "evergreen-ui";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: "",
      metadata: "",
      firstName: "",
      lastName: "",
      email: "",
      ssnLast4: "",
      birthday: "",
      isLoggedIn: false,
      bankSelected: false,
      accountSelected: false,
      index: 0,
      missingFirst: false,
      missingLast: false,
      missingEmail: false,
      missingSsnLast4: false,
      missingBirthday: false,
      loading: false,
      loadingMessage: "",
      errorMessage: "",
    };
    this.handleOnSuccess = this.handleOnSuccess.bind(this);
    this.handleOnExit = this.handleOnExit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePersonInfoSubmitClick = this.handlePersonInfoSubmitClick.bind(
      this
    );
    this.handleBankSelected = this.handleBankSelected.bind(this);
    this.handleAccountSelect = this.handleAccountSelect.bind(this);
    this.handleLoading = this.handleLoading.bind(this);
  }

  columns = [
    {
      Header: "Account Name",
      accessor: "name", // String-based value accessors - pulls from data
      width: 400,
    },
    {
      Header: "Type",
      accessor: "type",
      width: 200,
    },
    {
      Header: "Select",
      Cell: (row) => (
        <div>
          <button onClick={() => this.handleAccountSelect(row.index)}>
            Select this account
          </button>
        </div>
      ),
      width: 200,
    },
  ];

  handlePersonInfoSubmitClick() {
    //first check all forms
    var shouldSubmit = true;
    if (this.state.firstName === "") {
      this.setState({ missingFirst: true });
      shouldSubmit = false;
    } else {
      this.setState({ missingFirst: false });
    }
    if (this.state.lastName === "") {
      this.setState({ missingLast: true });
      shouldSubmit = false;
    } else {
      this.setState({ missingLast: false });
    }
    if (this.state.email === "") {
      this.setState({ missingEmail: true });
      shouldSubmit = false;
    } else {
      this.setState({ missingEmail: false });
    }

    if (this.state.ssnLast4 === "" || this.state.ssnLast4.length !== 4) {
      this.setState({ missingSsnLast4: true });
      shouldSubmit = false;
    } else {
      this.setState({ missingSsnLast4: false });
    }

    function isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    }

    if (this.state.birthday === "" || !isValidDate(this.state.birthday)) {
      this.setState({ missingBirthday: true });
      shouldSubmit = false;
    } else {
      this.setState({ missingBirthday: false });
    }

    if (shouldSubmit) {
      this.setState({ isLoggedIn: true });
    } else {
      toaster.danger("Please fill in all fields");
    }
    this.handleLoading("Securely saving personal information...");
  }

  handleBankSelected() {
    this.setState({ bankSelected: true });
  }

  handleAccountSelect = async (accountId) => {
    const bYear = new Date(this.state.birthday).getFullYear();
    const bMonth = new Date(this.state.birthday).getMonth() + 1;
    const bDay = new Date(this.state.birthday).getDate();
    this.setState(() => ({ loading: true }));

    try {
      await axios.post("/api/users/create-user", {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        accountId: accountId,
        token: this.state.token,
        ssnLast4: this.state.ssnLast4,
        bYear,
        bMonth,
        bDay,
      });
      this.setState(() => ({ accountSelected: true, loading: false }));

      toaster.success("Your account is securely linked!", {
        description: "You will receive payment in 2-5 business days.",
      });
    } catch (error) {
      this.setState(() => ({
        errorMessage: "Something went wrong",
        loading: false,
      }));
      console.log(
        "There has been a problem with your axios operation: " + error.message
      );
    }
  };

  handleChange = async function (event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    await this.setState({
      //async so we capture the last character entered
      [name]: value,
    });
  };
  handleOnSuccess(token, metadata) {
    // send token to client server
    this.setState({ token: token });
    this.setState({ metadata: metadata });

    toaster.success("Retrieved bank account information");

    this.handleBankSelected();
  }
  handleOnExit() {
    // handle the case when your user exits Link
  }

  handleLoading(loadingMessage) {
    this.setState({ loadingMessage: loadingMessage });
    this.setState({ loading: true });

    setTimeout(
      function () {
        console.log("three seconds");
        this.setState({ loading: false });
        if (this.state.isLoggedIn && !this.state.bankSelected) {
          toaster.success("Personal information saved!");
        } else if (this.state.accountSelected) {
          toaster.success("Your account is securely linked!", {
            description: "You will receive payment in 2-5 business days.",
            duration: 10000,
          });
        }
      }.bind(this),
      //return Math.floor(Math.random()*(max-min+1)+min);
      Math.floor(Math.random() * 3000 + 1000) //delay between 3 seconds and 5 seconds
    );
  }
  render() {
    const isLoggedIn = this.state.isLoggedIn;
    const bankSelected = this.state.bankSelected;
    const accountSelected = this.state.accountSelected;
    const loading = this.state.loading;
    let button;

    if (loading) {
      //dispaly loading screen
      button = (
        <Pane
          alignItems="center"
          justifyContent="center"
          background="#F1FBFC"
          width="auto"
          height="auto"
          padding={24}
          marginTop="100px"
          border="default"
        >
          <Spinner marginX="auto" marginY={120} size={200} />
          <p className="test">{this.state.loadingMessage}</p>
        </Pane>
      );
    } else if (!isLoggedIn) {
      button = (
        <Pane>
          <Pane
            background="#F1FBFC"
            width="100%"
            height="auto"
            padding={24}
            marginTop="100px"
            border="default"
          >
            <Icon icon="lock" color="info" marginRight={16} />
            SwoopPay is the safe and speedy way to receive your ride, event, and
            tip payouts. <br /> <br />
            Create your SwoopPay account and click “Get Started” in order to
            securely link your bank account, and receive your next payout
            instantly via direct deposit. Get paid the easy way––with SwoopPay.
          </Pane>
          <Pane
            background="#F5F6F7"
            width="100%"
            height="auto"
            padding={24}
            marginTop="20px"
            border="default"
          >
            <TextInputField
              isInvalid={this.state.missingFirst}
              label="First Name"
              name="firstName"
              onChange={this.handleChange}
            />
            <TextInputField
              isInvalid={this.state.missingLast}
              label="Last Name"
              name="lastName"
              onChange={this.handleChange}
            />
            <TextInputField
              isInvalid={this.state.missingEmail}
              label="Email"
              name="email"
              onChange={this.handleChange}
            />
            <TextInputField
              isInvalid={this.state.missingSsnLast4}
              label="SSN last 4"
              name="ssnLast4"
              onChange={this.handleChange}
            />
            <div>
              <DatePicker
                selected={this.state.birthday}
                onChange={(date) => {
                  this.setState({ birthday: date });
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                customInput={
                  <TextInputField
                    isInvalid={this.state.missingBirthday}
                    label="Birthday"
                    name="birthday"
                  />
                }
              />
            </div>
            <Button onClick={this.handlePersonInfoSubmitClick}>
              Get Started
            </Button>
          </Pane>
        </Pane>
      );
    } else if (isLoggedIn && !bankSelected) {
      //plaid link button should show
      button = (
        <Pane>
          <Pane
            background="#47B881"
            width="50%"
            height="auto"
            padding={24}
            marginTop="100px"
            border="default"
          >
            <Heading marginBottom="20px">
              {" "}
              <Icon icon="lock" color="info" marginRight={16} />
              Set up direct deposit:
            </Heading>
            <PlaidLink
              clientName="SwoopPay"
              env={process.env.REACT_APP_PLAID_ENV_NAME}
              user={{
                // legalName: this.state.firstName + this.state.lastName,
                // emailAddress: this.state.email
                legalName: this.firstName + " " + this.lastName,
                emailAddress: this.email,
              }}
              product={["auth"]}
              publicKey="1cec057fc5041886b48ccc5bc7ec9c"
              onExit={this.handleOnExit}
              onSuccess={this.handleOnSuccess}
            >
              Connect Bank Account
            </PlaidLink>
          </Pane>
          <Pane
            background="#F5F6F7"
            width="100%"
            height="auto"
            padding={24}
            marginTop="100px"
            border="default"
          >
            <Heading marginBottom="20px">
              Your saved contact information:
            </Heading>
            <TextInputField
              isInvalid={false}
              value={this.state.firstName}
              label={
                <Pane>
                  First Name
                  <Icon icon="tick-circle" color="success" marginLeft={5} />
                </Pane>
              }
              name="firstName"
              onChange={this.handleChange}
              disabled
            />
            <TextInputField
              isInvalid={false}
              value={this.state.lastName}
              label={
                <Pane>
                  Last name
                  <Icon icon="tick-circle" color="success" marginLeft={5} />
                </Pane>
              }
              name="lastName"
              onChange={this.handleChange}
              disabled
            />
            <TextInputField
              isInvalid={false}
              value={this.state.email}
              label={
                <Pane>
                  Email
                  <Icon icon="tick-circle" color="success" marginLeft={5} />
                </Pane>
              }
              name="email"
              onChange={this.handleChange}
              disabled
            />
          </Pane>
        </Pane>
      );
    } else if (isLoggedIn && bankSelected && !accountSelected) {
      //isLoggedIn and bankSelected
      button = (
        <Pane
          background="#F5F6F7"
          width="100%"
          height="auto"
          padding={24}
          marginTop="100px"
          border="default"
        >
          <p>Select Desired Account:</p>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell>Account Name</Table.TextHeaderCell>
              <Table.TextHeaderCell>Type</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {this.state.metadata.accounts
                .filter(
                  (account) =>
                    account.type === "depository" &&
                    (account.subtype === "checking" ||
                      account.subtype === "savings")
                )
                .map((account, currentIndex) => (
                  <Table.Row
                    key={account.name}
                    isSelectable
                    onSelect={() => this.handleAccountSelect(account.id)}
                  >
                    <Table.TextCell>{account.name}</Table.TextCell>
                    <Table.TextCell>{account.type}</Table.TextCell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Pane>
      );
    } else {
      //isLoggedIn and bankSelected and accountSelected
      button = (
        <Pane
          border="default"
          background="#D4EEE2"
          padding={20}
          marginTop="200px"
          elevation={4}
        >
          You can now receive payments from Swoop! <br /> Please email{" "}
          <a href="mailto:payment@swoopapp.com">payment@swoopapp.com</a> with
          any questions.
        </Pane>
      );
    }
    return (
      <div className="flex-container">
        <div className="header">
          <Pane
            height="100%"
            width="100%"
            background="#E4E7EB"
            padding={(50, 50, 50, 75)}
            border="muted"
            elevation={2}
          >
            <img src={logo} className="App-logo" alt="logo" />
            <Card color="#66788A">SwoopPay Partner Payment Portal</Card>
          </Pane>
        </div>
        <div width="100%">
          <Pane width="60vmin" margin={(50, 50, 50, 50)}>
            {" "}
            {button}
            {!!this.state.errorMessage && <div>{this.state.errorMessage}</div>}
          </Pane>
        </div>
      </div>
    );
  }
}

export default App;
